<template>
  <Layout :top-bar-title="title" :sections="sections" :baseLayoutInfoUrl="'/base-layout-info/'">
    <ProductBrandTable/>
  </Layout>
</template>

<script>
import Layout from '@/apps/core/components/Layout'
import ProductBrandTable from '../components/tables/ProductBrandTable'
import sections from '../../dido/views/defaultNavigationDrawerSections'

export default {
  components: { ProductBrandTable, Layout },
  data () {
    return {
      sections: sections,
      title: this.$t('brand.list')
    }
  }
}
</script>

<style scoped>

</style>
