<template>
  <v-form @submit.prevent="saveForm" ref="form">
    <BreadcrumbsField :items="itemsBreadcrumbs"/>
    <v-card>
      <v-card-title>
        <h2 class="px-3">{{description}}</h2>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                :label="$t('brand.codBrand')"
                v-model="external_id"
                :rules="[isEmptyRule]"
                disabled
              />
              <v-text-field
                :label="$t('brand.title')"
                v-model="description"
                :rules="[isEmptyRule]"
                disabled
              />
              <v-autocomplete
                :label="$t('ProductCategory.title')"
                v-model="product_category"
                :items="productCategories"
                item-value="id"
                item-text="name"
                return-object/>
              <v-text-field
                label="Color"
                v-model="color"
                :rules="[isColorRule]"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="cancel" color="primary" text @click="closeForm">{{ $t('general.cancel') }}</v-btn>
        <v-btn class="save" color="primary" @click="saveForm">{{ $t('general.save') }}</v-btn>
      </v-card-actions>
      <PrettyAlert :text="textAlert" v-model="showAlert"/>
    </v-card>
  </v-form>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { validationMixin } from '@/apps/core/mixins/validations'
import PrettyAlert from '@/lib/unlogin/components/PrettyAlert'
import BreadcrumbsField from '@/apps/core/components/forms/BreadcrumbsField'

export default {
  name: 'ProductBrandForm',
  props: {
    showHeader: Boolean
  },
  components: { PrettyAlert, BreadcrumbsField },
  mixins: [validationMixin],
  data () {
    return {
      textAlert: '',
      showAlert: false,
      itemsBreadcrumbs: [
        {
          text: this.$t('brand.list'),
          disabled: false,
          name: 'ProductBrandList'
        },
        {
          text: this.$t('brand.title'),
          disabled: true
        }
      ]
    }
  },
  computed: {
    ...mapState('productBrands', ['currentItem', 'productCategories']),
    external_id: {
      get () {
        return this.currentItem.external_id
      },
      set (value) {
        this.updateCurrentItem({ ...this.currentItem, external_id: value })
      }
    },
    description: {
      get () {
        return this.currentItem.description
      },
      set (value) {
        this.updateCurrentItem({ ...this.currentItem, description: value })
      }
    },
    color: {
      get () {
        return this.currentItem.color
      },
      set (value) {
        this.updateCurrentItem({ ...this.currentItem, color: value })
      }
    },
    product_category: {
      get () {
        return this.currentItem.product_category
      },
      set (value) {
        this.updateCurrentItem({ ...this.currentItem, product_category: value })
      }
    }
  },
  methods: {
    ...mapActions('productBrands', ['postItem', 'updateCurrentItem', 'getProductCategories']),
    closeForm () {
      this.$refs.form.reset()
      this.updateCurrentItem({})
      this.$emit('close')
    },

    showAlertAction (text) {
      this.textAlert = text
      this.showAlert = true
    },

    saveForm () {
      if (this.$refs.form.validate()) {
        this.postItem({ ...this.currentItem, product_category: this.currentItem.product_category.id })
          .then(
            () => {
              this.$emit('save')
              this.$refs.form.reset()
              this.closeForm()
            })
          .catch((err) => {
            console.error(err)
            this.showAlertAction('Error al guardar')
          })
      }
    }
  },
  mounted () {
    this.getProductCategories()
  }
}
</script>

<style scoped>

</style>
