<template>
  <CrudTable :storeModule="storeModule" :headers="headers" @save="onTopicEdition" :addItems=false>
    <template v-slot:form="{close}">
      <ProductBrandForm showHeader @save="afterCreation" @close="close"/>
    </template>

    <template v-slot:item.external_id="{ item }">
      <router-link :to="{ name: 'ProductBrandDetail', params: { id: item.external_id }}">
        {{ item.external_id }}
      </router-link>
    </template>

  </CrudTable>
</template>

<script>
import Lset from 'lodash/set'
import ProductBrandForm from '../../components/forms/ProductBrandForm'
import CrudTable from '@/lib/uncrudtable/components/CrudTable'
import { mapActions } from 'vuex'

export default {
  name: 'ProductBrandTable',
  components: { ProductBrandForm, CrudTable },

  data () {
    return {
      storeModule: 'productBrands',
      headers: [
        { text: this.$t('general.codBrand'), value: 'external_id', hide: false },
        { text: this.$t('general.brand'), value: 'description', editable: false, hide: false },
        { text: this.$t('general.categoryProduct'), value: 'product_category.name', hide: false }
      ]
    }
  },
  computed: {},
  methods: {
    ...mapActions('productBrands', ['reloadItemList']),
    afterCreation () {
      this.reloadItemList()
    },

    onTopicEdition (data) {
      const updatedItem = Lset(data.item, data.header, data.value)

      this.$store
        .dispatch(`${this.storeModule}/postItem`, updatedItem)
        .then(
          () => this.reloadItemList()
        )
    }
  }
}
</script>

<style scoped>

</style>
